
/* Screenshoot Upload Box */
.proof {
    border-radius:15px;
    padding:12px;
    text-align:center;
    background-color: white;
    box-shadow:  5px 5px 15px #c7c7c7,-5px -5px 24px #f9f9f9;

}
