
.search__bar{
    min-width: 360px;
    padding: 0 20px;
}

@media (max-width: 1199px){
    .header .search__bar{
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .search__bar__show{
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 572px){
    .search__bar {
        min-width: 10px  !important;
    }


}

.search__form {
    width: 100%;
}

.search__form input{
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.3) ;
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}
 

.search__form input:focus,
.search__form input:hover{
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}


.search__form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}


.error{
    /* make it display  that show the error message without moving the html elements */
    position: absolute;

    color: red;
    font-weight: 500;
}
