.header-nav ul {
    list-style: none;
}

.header-nav > ul {
    margin: 0;
    padding: 0;
}

.header-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
}

.header-nav .nav-profile {
    color: #012970;
}

.header-nav .nav-profile img {
    max-height: 36px !important;
    min-height: 36px !important;
    min-width: 36px !important;
    max-width: 36px !important;
}


.header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}


.header-nav .badge-number{
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: 700;
    font-size: 9px;
    padding:  3px 6px;
    border-radius: 50px;
}

.header-nav .notifications{
    inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notifications-item{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.headeer-nav .notifications .notifications-item:hover{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.header-nav .notifications .notifications-item img{
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.header-nav .notifications .notifications-item span{
    background-color: #f6f9ff;
}

.header-nav .notifications .notifications-item i{
    margin: 0 20px 0 10px;
    font-size: 30px;
}

.header-nav .messages {
    inset: 8px -15px auto auto !important;
}

.header-nav .messages .messages-item{
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .messages .messages-item a{
    display: flex;
}   

.header-nav .messages .messages-item img{
    margin: 0 20px 0 10px;
    max-height: 40px;
}

.header-nav .messages .messages-item h4{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
}

.header-nav .messages .messages-item:hover {
    background-color: #f6f9ff;
}

.header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
}

.header-nav .profile .dropdown-header h6{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
}

.header-nav .profile .dropdown-header span{
    font-size: 14px;
}

.header-nav .profile .dropdown-item{
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.header-nav .profile .dropdown-item i{
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.header-nav .profile .dropdonw-item:hover{
    background-color: #f6f9ff;
}   

.dropdown-footer{
    display: flex;
    align-items: center;
    justify-content: center;
}










