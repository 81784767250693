.logo{
    line-height: 1;
}


@media (min-width: 1200px){
    .logo{
        width: 245px;
    }
}

.logo img{
    max-height: 26px;
    margin-right: 6px;
}


.logo a{
    text-decoration: none !important;
    color: inherit
}

.logo span{
    font-size: 26px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
