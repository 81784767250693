input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Change white to your desired background color */
    -webkit-text-fill-color: #000 !important; /* Change the text color */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background from flashing */
}

input:autofill {
    box-shadow: 0 0 0 1000px white inset; /* Change white to your desired background color */
    -moz-text-fill-color: #000 !important; /* Change the text color */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Your input background color */
    -webkit-text-fill-color: #000; /* Your input text color */
    transition: background-color 5000s ease-in-out 0s;
}

.container {
    padding: 20px;
}
  
.screen {		
	position: relative;	
	height: 600px;
	width: 360px;	
	box-shadow: 0px 0px 24px #696969;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFFFFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #BEBEBE;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #A9A9A9, #808080);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #B0B0B0;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 156px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #A9A9A9;
}

.login__input {
	border: none;
	border-bottom: 2px solid #BEBEBE;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #808080;
}

.login__submit {
	background: #FFFFFF;
	font-size: 14px;
	margin-top: 50px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #C0C0C0;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #808080;
	box-shadow: 0px 2px 2px #696969;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #808080;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #A9A9A9;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #D3D3D3;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #D3D3D3;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #808080;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}

.error{
    /* make it display  that show the error message without moving the html elements */
    position: absolute;

    color: red;
    font-weight: 500;

}
