@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  scroll-behavior: smooth;
  --primary-color: #012970;
  --secondary-color: #899bbd;
		
}

html{
  font-size: 85%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: black;
  font-family:  "Raleway", sans-serif;;
   
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family:  "Raleway", sans-serif;;
}

a {
  text-decoration: none !important; /* Disable the underline */
  color: inherit; /* Ensure the color inherits from the parent */
}

/* ------------------------------------------------------------------------------- */
/* overwrite for the bootstrap stylings for the dropdown menu for navigation */
/* ------------------------------------------------------------------------------- */

.page-title {
  margin-bottom: 10px;
}

.page-title h1{
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

.breadcrumb-item a{
  font-size: 18px;
}

.breadcrumb-gap{
  margin-left: 6px;
}

.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  --webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  --webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  --webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer{
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i{
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover{
  background-color: #f6f9ff;
}

@media (min-width: 768px){
  .dropdown-menu-arrow::before{
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  } 
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0%{
    opacity: 0;
  }
}

@keyframes dropdown-animate{
  0% {
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.collapse.show {
  display: block !important;   /* Ensure the content is shown */
  visibility: visible !important; /* Make sure the content is visible */
  overflow: visible !important;  /* Allow overflow in case content is larger */
}

.collapse:not(.show) {
  visibility: hidden !important; /* Hide content when not expanded */
  overflow: hidden !important;   /* Prevent content overflow when collapsed */
}

.collapse {
  transition: height 0.3s ease !important;
}

.w-100 {
  width: 100% !important;
} 

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


